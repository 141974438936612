@import "./variables.scss";

* {
    scrollbar-width: thin;
    scrollbar-color: var(--background-color) $DarkBlue;
}

.scrollbar-custom {
    flex: 1;
    white-space: normal;
    word-wrap: break-word;
    /* For Firefox */
    scrollbar-color: var(--background-color);

    &::-webkit-scrollbar-thumb {
        background-color: var(--background-color) $DarkBlue;
        /* Scrollbar thumb color */
        border-radius: 4px;
        /* Rounded corners */
    }

    &::-webkit-scrollbar-track {
        background-color: var(--background-color);
        /* Scrollbar track color */
        border-radius: 4px;
        /* Rounded corners */
    }
}