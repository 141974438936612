@import "./variables.scss";

// 100% window containers
.generic-full-container {
  @extend .generic-container;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.form-container,
.payment-container,
.plan-container,
.role-container,
.login-container,
.register-container,
.payment-success-container,
.manage-subscription-container,
.generic-fit-container {
  @extend .generic-container;
  width: fit-content;
  margin: 40px auto;
}

// Fit to window containers
.generic-container {
  min-width: 400px;
  padding: 20px;
  border: 1px solid var(--accent-color);
  box-shadow: 0 2px 4px var(--text-color);

  .login-form,
  .register-form {
    max-width: 400px;
  }

  input {
    background-color: var(--background-light-color) !important;
    color: var(--accent-color) !important;
    border-color: transparent !important;
    padding: 8px;
    margin-bottom: 5px;
    width: 100%;
  }

  textarea {
    background-color: var(--background-light-color) !important;
    box-shadow: 0 0px 3px 1px var(--text-color);
    color: var(--accent-color) !important;
    border-color: transparent !important;
    padding: 8px;
    margin-bottom: 5px;
    width: 100%;
    height: 100%;
    min-height: 300px;
  }

  option {
    padding-bottom: 5px;
  }

  .custom-checkbox {
    /* Hide the default checkbox appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Custom styling */
    background-color: var(--background-light-color) !important;
    border: 2px solid var(--primary-color) !important;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    &:checked {
      background-color: var(--accent-color) !important;
      color: var(--background-light-color) !important;
      border-color: transparent !important;
    }

    &:checked::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid var(--background-light-color);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:focus {
      outline: none;
    }
  }

  button {
    background-color: var(--background-light-color) !important;
    color: var(--accent-color) !important;
    border: none !important;
    margin: 0;
    padding: 0;
    height: 0;
    width: 100%;
  }

  .btn {
    color: var(--secondary-color);
    cursor: pointer;
    margin: 0 0 2em;
    position: relative;
    text-decoration: none;
    width: 100%;
    max-width: 358px;

    &:disabled,
    &.disabled {
      cursor: not-allowed;
    }
  }

  .btn-1 {
    font-weight: 100;

    &:disabled,
    &.disabled {
      cursor: not-allowed;
    }

    svg {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    rect {
      fill: none;
      stroke: var(--accent-color);
      stroke-width: 2;
      stroke-dasharray: 422, 0;
      transition: all 0.35s linear;
    }
  }

  .btn-1:hover {
    background: rgba(var(--text-color), 0);
    font-weight: 400;

    rect {
      stroke-width: 2;
      stroke-dasharray: 23, 370;
      stroke-dashoffset: 48;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  // Login and register headers
  .login-header,
  .register-header {
    text-align: center;
    margin-bottom: 20px;

    h2 {
      font-size: 24px;
      font-weight: bold;
      color: var(--link-color);
    }

    p {
      font-size: 16px;
    }
  }

  .forgot-password,
  .tos,
  .question-link,
  .login-link,
  .register-link {
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
  }

  .checkbox-container {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;

    .checkbox-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      input[type="checkbox"] {
        margin-bottom: 0.5rem;
      }

      label {
        margin-top: 0.25rem;
      }
    }
  }

  // Expandables
  .clickable-title {
    cursor: pointer;
    /* Changes cursor to pointer on hover */
    text-decoration: none;
    /* Remove underline */
    display: flex;
    /* Flex to align items */
    justify-content: space-between;
    /* Space between title and toggle icon */
    align-items: center;
    /* Center align items vertically */
    padding: 10px;
    /* Add some padding */
    background-color: var(--background-light-color);
    /* Light border */
    border-radius: 4px;
    /* Rounded corners */
    transition: background-color 0.3s ease;
    /* Transition for background color */
  }

  .clickable-title:hover {
    background-color: var(--background-light-color);
    /* Slightly darker background on hover */
  }

  .toggle-icon {
    transition: transform 0.3s ease;
    /* Transition for rotation */
  }

  .toggle-icon.expanded {
    transform: rotate(180deg);
    /* Rotate the icon when expanded */
  }

  .guide-image {
    max-width: 100%;
    /* Responsive image */
    margin: 10px 0;
    /* Margin for spacing */
    transition: opacity 0.3s ease;
    /* Transition for image opacity */
  }

  .guide-content {
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--background-light-color);
    border-radius: 5px;
    box-shadow: 0 1px 2px var(--text-color);
    position: relative;
  }

  .points-description {
    margin-bottom: 10px;
    /* Space between points */
  }
}

.dropdown-container {
  &-margin {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;

    .dropdown {
      margin-right: 15px;
      margin-top: 10px;
    }
  }

  &-space-around {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
    justify-content: space-around;
  }

  label {
    margin-right: 10px;
  }
}

select,
input {
  background-color: var(--background-light-color) !important;
  color: var(--accent-color) !important;
  border-color: transparent !important;
  padding: 6px;
}

.input-width-fixed {
  width: 358px;
}

option {
  padding-bottom: 10px;
}

@media (max-width: 500px) {

  .generic-container,
  .form-container,
  .payment-container,
  .plan-container,
  .role-container,
  .login-container,
  .payment-success-container,
  .manage-subscription-container,
  .register-container {
    width: 356px;
    min-width: unset;

    .btn {
      max-width: 215px;
    }
  }

}