/* You can add global styles to this file, and also import other style files */
//Dark mode
$dark-Navy : #0a192f;
$dark-LightNavy : #112240;
$dark-LightestNavy : #303C55;
$dark-Slate : #8892b0;
$dark-LightSlate : #a8b2d1;
$dark-LightestSlate: #ccd6f6;
$dark-White : #e6f1ff;
$dark-Green : #64ffda;

//Light mode
$Navy : #f7faff;
$LightNavy : #eaf3fe;
$LightestNavy : #d1e4fc;
$Slate : #5d6a7d;
$LightSlate : #3e4b5b;
$LightestSlate: #2b3443;
$White : #ffffff;
$Green : #295e94;

:root {
  --background-color: #{$Navy};
  --background-light-color: #{$LightNavy};
  --text-color: #{$LightestNavy};
  --primary-color: #{$Slate};
  --secondary-color: #{$LightSlate};
  --accent-color: #{$Green};
  --link-color: #{$LightestSlate};
}

// Dark Mode Styles: Override variables when the `.dark-mode` class is added
.dark-mode {
  --background-color: #{$dark-Navy};
  --background-light-color: #{$dark-LightNavy};
  --text-color: #{$dark-LightestNavy};
  --primary-color: #{$dark-Slate};
  --secondary-color: #{$dark-LightSlate};
  --accent-color: #{$dark-Green};
  --link-color: #{$dark-LightestSlate};
}

@media (prefers-color-scheme: dark) {
  .dark-mode {
    --background-color: #{$dark-Navy};
    --background-light-color: #{$dark-LightNavy};
    --text-color: #{$dark-LightestNavy};
    --primary-color: #{$dark-Slate};
    --secondary-color: #{$dark-LightSlate};
    --accent-color: #{$dark-Green};
    --link-color: #{$dark-LightestSlate};
  }
}

// Light mode
$SoftBlue : #d9e6f5;
$SkyBlue : #c3d8f1;
$LightGrayBlue: #f0f4f8;
$GrayishBlue : #5a6a8e;
$CoolGray : #8798b1;
$PaleGray : #f2f7fc;
$OffWhite : #f7faff;
$LightGreen : #a5ffe4;


$DarkBlue : #295e94; // Dark blue
$DarkerBlue : #073c72; // Darker blue
$VeryDarkBlue : #093c72; // Very dark blue
$LightBlue : #a2c2e8; // Light blue
$NearWhite : #bbc8d4; // Near white

$DarkBlueTransparent : rgba(41, 94, 148, 0.5); // 50% opacity
$DarkerBlueTransparent : rgba(7, 60, 114, 0.5); // 50% opacity
$VeryDarkBlueTransparent : rgba(9, 60, 114, 0.5); // 50% opacity
$LightBlueTransparent : rgba(162, 194, 232, 0.5); // 50% opacity
$NearWhiteTransparent : rgba(187, 200, 212, 0.5); // 50% opacity
$DarkRedTransparent : rgba(139, 0, 0, 0.5); // 50% opacity

$Aqua : aqua;
$Red : red;
$FreshGreen : #4caf50;
$VibrantRed : #f44336;
$SkyBlue : #2196f3;
$BrightOrange : #ff9800;

$MainFont: Calibre, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;
$CodeFont: SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace;

.gradient-background {
  background: linear-gradient(135deg,
      $DarkBlue,
      $DarkBlueTransparent,
      $DarkerBlueTransparent,
      $VeryDarkBlue,
      $LightBlueTransparent,
      var(--text-color),
      $NearWhiteTransparent,
      $DarkRedTransparent,
      $Red);

  background-size: 200% 200%; // Set size for slight motion
  animation: subtle-motion 12s ease infinite;
}

// Subtle motion animation
@keyframes subtle-motion {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}