@import "./variables.scss";

body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: var(--background-color) !important;
  color: var(--primary-color) !important;
  transition: background-color 0.3s ease, color 0.3s ease;
  line-height: 1.3;
  font-family: $MainFont;
  font-size: 20px;
}

p {
  color: var(--primary-color);
}

.no-grid-data {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--accent-color);

  &:before {
    content: '▶';
    position: absolute;
    left: 0;
    color: var(--accent-color);
  }
}

.ul-pointer {
  position: relative;
  padding-left: 20px;
  padding-right: 5px;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--primary-color);

  &:before {
    content: '▹';
    position: absolute;
    left: 0;
    color: var(--primary-color);
  }
}

.section-box {
  padding: 130px 0;
}

.section-box-2 {
  padding: 90px 0;
}

.code-font {
  font-family: $CodeFont;
}

.e-font {
  font-family: $MainFont;
}

.section-title {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0 40px;
  width: 100%;
  white-space: nowrap;
  font-size: 32px;
  font-weight: 600;
  color: var(--link-color);
  font-family: $MainFont;
  margin: 0 0 10px 0;

  .n-section-title {
    margin-right: 10px;
    font-family: $CodeFont;
    font-weight: normal;
    color: var(--accent-color);
    font-size: 20px;
    position: relative;
    bottom: 4px;
  }

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 300px;
    background-color: var(--text-color);
    position: relative;
    top: -5px;
    margin-left: 20px;
  }
}

.dark-theme-btn {
  color: var(--accent-color);
  background-color: transparent;
  border: 1px solid var(--accent-color);
  border-radius: 3px;
  padding: 2px 13px;
  font-size: 15px;
  font-family: $CodeFont;
  text-align: left;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: var(--background-light-color);
    outline: none;
    transition: all 0.3s ease-in-out;
  }
}

.main-btn {
  color: var(--accent-color);
  background-color: transparent;
  border: 1px solid var(--accent-color);
  border-radius: 3px;
  font-size: 13px;
  font-family: $CodeFont;
  line-height: 1;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 1.25rem 1.75rem;

  &-icon {
    font-size: 23px;
    padding: 1.0rem 1.0rem;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: var(--background-light-color);
    outline: none;
    transition: all 0.3s ease-in-out;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    color: gray;
  }
}

.icon-order {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  // Reduce or remove gap on smaller screens
  @media (max-width: 600px) {
    gap: 4px;
  }

  margin-bottom: 1rem;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: var(--accent-color);
  }
}

.underline {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: var(--accent-color) !important;
}

.underline:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 3px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: var(--accent-color);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0%;
}

.underline:hover:after {
  width: 100%;
  left: 0;
}

.size-transition {
  transition: all 500ms ease-out;

  &.full {
    transition: all 500ms ease-in;
  }
}

.margin-right-left-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-left-6 {
  margin-left: 6px;
}

.bottom-border-1px {
  border-bottom: 1px solid var(--accent-color);
}

.top-border-1px {
  border-bottom: 1px solid var(--accent-color);
}

blockquote {
  border-left: 4px solid var(--link-color);
  padding-left: 10px;
  color: inherit;
  font-style: italic;
  margin: 10px 0;
}

pre {
  background-color: var(--text-color);
  color: var(--link-color);
  padding: 1rem;
  overflow-x: auto;
  max-height: 400px;
  font-family: $CodeFont;
  white-space: pre-wrap;
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.3);
}

code {
  background: none;
  color: var(--link-color) !important;
  font-size: 1rem;
}

.graph {
  overflow: hidden;
  padding: 20px;
  margin: 10px auto;
  border: 1px solid var(--accent-color);
}

.points-description {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  font-size: 18px;

  &:before {
    content: '▹';
    position: absolute;
    left: 0;
    color: var(--accent-color);
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;

  /* Enable horizontal scrolling on small screens */
  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 9px;
      text-align: left;
      border-bottom: 1px solid var(--background-light-color);
    }

    thead th {
      cursor: default;
      line-height: 1.5;
    }

    tbody {
      tr {

        &:hover,
        &:focus {
          background-color: var(--background-light-color);
        }
      }
    }
  }
}

/* Responsive table for small screens */
@media (max-width: 768px) {

  /* Style for table responsiveness */
  .table-container {
    overflow-x: auto;

    /* Hide non-essential columns on small screens */
    .hide-on-mobile {
      display: none;
    }

    /* Alternative: Stacking rows */
    table {
      display: block;
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .proyect-container {
    margin: 30px 0 !important;
    height: 385px !important;
  }
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .container {
    max-width: 950px;
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .container {
    max-width: 800px;
  }
}

@media (min-width: 880px) and (max-width: 992px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 768px) and (max-width: 880px) {
  .container {
    max-width: 600px;
  }
}

@media (max-width: 768em) {
  .section-title {
    font-size: 24px;
  }

  .container {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .display-none {
    display: none !important;
  }

  .banner h2,
  .banner h3,
  .big-title {
    font-size: 70px !important;
  }

  .big-subtitle {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {

  .banner h2,
  .banner h3,
  .big-title {
    font-size: 60px !important;
  }
}

@media (max-width: 480px) {

  .banner h2,
  .banner h3,
  .big-title {
    font-size: 50px !important;
  }
}

@media (max-width: 376px) {

  .banner h2,
  .banner h3,
  .big-title {
    font-size: 40px !important;
  }
}

@media (max-width: 30em) {
  body {
    font-size: 18px;
  }
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}